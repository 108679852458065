import React from 'react';

import FraudIcon from 'components/molecules/Icon/FraudIcon';
import ShippingIcon from 'components/molecules/Icon/ShippingIcon';
import WarehouseIcon from 'components/molecules/Icon/WarehouseIcon';
import ProductActionIcon from 'components/molecules/Icon/ProductActionIcon';
import PaymentIcon from 'components/molecules/Icon/PaymentIcon';
import C360Icon from 'components/molecules/Icon/C360Icon';
import RuleEngineIcon from 'components/molecules/Icon/RuleEngineIcon';
import ReturnIcon from 'components/molecules/Icon/ReturnIcon';
import ProIcon from 'components/molecules/Icon/ProIcon';
import QCIcon from 'components/molecules/Icon/QCIcon';
import CurationIcon from 'components/molecules/Icon/CurationIcon';
import LegacyIcon from 'components/molecules/Icon/LegacyIcon';
import DiscoXpIcon from './components/molecules/Icon/DiscoXpIcon';
import CampaignManagementIcon from 'components/molecules/Icon/CampaignManagementIcon';
import ChatModerationIcon from 'components/molecules/Icon/ChatModerationIcon';
import ROUTES from 'utils/ROUTES';

import getEnvironment from 'environments';

export interface IMenuItem {
  key: string;
  link: string;
  icon: any;
  prod?: boolean;
  children?: IMenuItem[];
}

interface IMenu {
  apps: IMenuItem[];
  qa: IMenuItem[];
}

export const menu: IMenu = {
  apps: [
    {
      key: 'LEGACY',
      link: 'https://bo.vestiairecollective.com',
      icon: LegacyIcon,
      prod: true,
    },
    {
      key: 'RETURN',
      link: ROUTES.RETURN,
      icon: ReturnIcon,
      prod: true,
    },
    {
      key: 'QC_V2',
      link:
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? 'https://qc.backoffice.vestiairecollective.com'
          : 'https://qc.backoffice.staging.vestiairecollective.com',
      icon: QCIcon,
      prod: true,
    },
    {
      key: 'AUTHENTICATION',
      link: getEnvironment().toolAuth,
      icon: QCIcon,
      prod: true,
    },
    {
      key: 'CURATION',
      link: ROUTES.CURATION,
      icon: CurationIcon,
      prod: true,
    },
    {
      key: 'COMMISSION',
      link: ROUTES.COMMISSION,
      icon: RuleEngineIcon,
      prod: true,
    },
    {
      key: 'C360',
      link: ROUTES.C360,
      icon: C360Icon,
      prod: true,
    },
    {
      key: 'WAREHOUSE',
      link: ROUTES.WAREHOUSE,
      icon: WarehouseIcon,
      prod: true,
    },
    {
      key: 'SHIPPING',
      link: ROUTES.SHIPPING,
      icon: ShippingIcon,
      prod: true,
    },
    {
      key: 'PRODUCTACTION',
      link: ROUTES.PRODUCTACTION,
      icon: ProductActionIcon,
      prod: true,
    },
    {
      key: 'B2CSELFSERVICE',
      link: ROUTES.B2CSELFSERVICE,
      icon: ProIcon,
      prod: true,
    },
    {
      key: 'EXTRA_PAYOUT',
      link: ROUTES.EXTRA_PAYOUT,
      icon: ProIcon,
      prod: true,
    },
    {
      key: 'FRAUD',
      link:
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? 'https://fraud.backoffice.vestiairecollective.com'
          : 'https://fraud.backoffice.staging.vestiairecollective.com',
      icon: FraudIcon,
      prod: true,
    },
    {
      key: 'PAYMENTSERVICE',
      link: ROUTES.PAYMENTSERVICE,
      icon: PaymentIcon,
      prod: true,
    },
    {
      key: 'CAMPAIGN_MANAGEMENT',
      link:
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? 'https://campaigns.backoffice.vestiairecollective.com'
          : 'https://campaigns.backoffice.staging.vestiairecollective.com',
      icon: CampaignManagementIcon,
      prod: true,
    },
    // Group service
    {
      key: 'WAREHOUSE_SERVICE',
      link: ROUTES.WAREHOUSE_SERVICE,
      icon: WarehouseIcon,
      prod: true,
      children: [
        {
          key: 'SWITCH_PICKUP',
          link: ROUTES.SWITCH_PICKUP,
          icon: WarehouseIcon,
          prod: true,
        },
        {
          key: 'SHIPPING_LABEL',
          link: ROUTES.SHIPPING_LABEL,
          icon: WarehouseIcon,
          prod: true,
        },
      ],
    },
    {
      key: 'TRANSPORT_SERVICE',
      link: ROUTES.TRANSPORT_SERVICE,
      icon: WarehouseIcon,
      prod: true,
      children: [
        {
          key: 'BULK_IMPORT',
          link: ROUTES.BULK_IMPORT,
          icon: WarehouseIcon,
          prod: true,
        },
      ],
    },
    // end Group
    {
      key: 'DISCO_XP_SERVICE',
      link: ROUTES.DISCO_XP_SERVICE,
      icon: DiscoXpIcon,
      prod: false,
    },
    {
      key: 'QC',
      link: ROUTES.QC,
      icon: QCIcon,
      prod: true,
    },
    {
      key: 'CHAT_MODERATION',
      link:
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? 'https://chat-moderation.backoffice.vestiairecollective.com/'
          : 'https://chat-moderation.backoffice.staging.vestiairecollective.com/',
      icon: ChatModerationIcon,
      prod: true,
    },
  ],
  qa: [],
};

export function createIcon(component: any, size: number) {
  return React.createElement(component, {
    size,
  });
}

export function shouldRender(item: IMenuItem) {
  if (item.prod) {
    return true;
  }

  return process.env.REACT_APP_ENVIRONMENT !== 'production';
}

export function findParent(
  menu: IMenuItem[],
  pathname: string,
): IMenuItem | null {
  for (let item of menu) {
    if (item.children) {
      if (item.children.some(child => child.link === pathname)) {
        return item;
      } else {
        let found = findParent(item.children, pathname);
        if (found) return found;
      }
    }
  }
  return null;
}
