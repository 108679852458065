import React, { Suspense, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { showHeaderAction } from 'store/GlobalAction';
import { GlobalContext } from 'store/GlobalContext';
import ROUTES from 'utils/ROUTES';
import getQueryParam from 'utils/useQuery';

import Loading from 'components/molecules/Loading';
import Header from 'components/organisms/Header/Header';
import SnackbarListener from 'components/organisms/SnackbarListener/SnackbarListener';
import Commission from './components/pages/Commission/Commission';
import SubServiceNav from 'components/organisms/SubServiceNav/SubServiceNav';
import ExtraPayout from 'components/pages/ExtraPayout/ExtraPayout';
import ShippingLabel from 'components/pages/ShippingLabel/ShippingLabel';

// LAZY COMPONENTS
const C360 = React.lazy(() => import('components/pages/C360/C360'));
const Callback = React.lazy(() => import('components/pages/Callback/Callback'));
const Curation = React.lazy(() => import('components/pages/Curation/Curation'));
const Home = React.lazy(() => import('components/pages/Home/Home'));
const Preferences = React.lazy(
  () => import('components/pages/Preferences/Preferences'),
);
const QualityControl = React.lazy(
  () => import('components/pages/QualityControl/QualityControl'),
);
const Return = React.lazy(() => import('components/pages/Return/Return'));
const B2CSelfService = React.lazy(
  () => import('components/pages/B2CSelfService/B2CSelfService'),
);
const PaymentService = React.lazy(
  () => import('components/pages/PaymentService/PaymentService'),
);
const Warehouse = React.lazy(
  () => import('components/pages/Warehouse/Warehouse'),
);
const Shipping = React.lazy(() => import('components/pages/Shipping/Tracking'));
const ProductAction = React.lazy(
  () => import('components/pages/ProductAction/ProductAction'),
);

const DiscoXpService = React.lazy(
  () => import('./components/pages/DiscoXpService/DiscoXpService'),
);
const SwitchPickup = React.lazy(
  () => import('./components/pages/SwitchPickup/SwitchPickup'),
);

const BulkImport = React.lazy(
  () => import('./components/pages/BulkImportDatafix'),
);

const AppContainer: React.FC = () => {
  const {
    dispatch,
    state: { showHeader, userTheme },
  } = useContext(GlobalContext);

  const hideheader = getQueryParam().get('hideheader');
  useEffect(() => {
    if (hideheader && hideheader !== '0' && hideheader !== 'false') {
      dispatch(showHeaderAction(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideheader]);

  return (
    <>
      <div
        className={`backoffice ${
          !showHeader ? 'no-header' : ''
        } theme-${userTheme} env-${process.env.REACT_APP_ENVIRONMENT}`}
      >
        <Header />
        <div className="main">
          <Suspense fallback={<Loading />}>
            <SubServiceNav />
            <Routes>
              <Route
                path={ROUTES.HOME}
                element={<Home />}
              />
              <Route
                path={ROUTES.C360}
                element={<C360 />}
              />
              <Route
                path={ROUTES.CALLBACK}
                element={<Callback />}
              />
              <Route
                path={ROUTES.CURATION}
                element={<Curation />}
              />
              <Route
                path={ROUTES.COMMISSION}
                element={<Commission />}
              />
              <Route
                path={ROUTES.QC}
                element={<QualityControl />}
              />
              <Route
                path={`${ROUTES.QC}/:productId`}
                element={<QualityControl />}
              />
              <Route
                path={ROUTES.RETURN}
                element={<Return />}
              />
              <Route
                path={ROUTES.WAREHOUSE}
                element={<Warehouse />}
              />
              <Route
                path={ROUTES.SHIPPING}
                element={<Shipping />}
              />
              <Route
                path={ROUTES.PRODUCTACTION}
                element={<ProductAction />}
              />
              <Route
                path={ROUTES.PREFERENCES}
                element={<Preferences />}
              />
              <Route
                path={ROUTES.B2CSELFSERVICE}
                element={<B2CSelfService />}
              />
              <Route
                path={ROUTES.PAYMENTSERVICE}
                element={<PaymentService />}
              />
              <Route
                path={ROUTES.EXTRA_PAYOUT}
                element={<ExtraPayout />}
              />
             <Route
             path={ROUTES.SHIPPING_LABEL}
             element={<ShippingLabel />}
           />
              {/* TODO */}
              <Route
                path={ROUTES.SWITCH_PICKUP}
                element={<SwitchPickup />}
              />
              <Route
                path={ROUTES.WAREHOUSE_SERVICE}
                element={
                  <Navigate
                    to={ROUTES.SWITCH_PICKUP}
                    replace
                  />
                }
              />
              <Route
                path={ROUTES.BULK_IMPORT}
                element={<BulkImport />}
              />
              <Route
                path={ROUTES.TRANSPORT_SERVICE}
                element={
                  <Navigate
                    to={ROUTES.BULK_IMPORT}
                    replace
                  />
                }
              />
              {/*  */}
              {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                <Route
                  path={ROUTES.DISCO_XP_SERVICE}
                  element={<DiscoXpService />}
                />
              )}
              <Route
                element={
                  <Navigate
                    to={ROUTES.HOME}
                    replace
                  />
                }
              />
            </Routes>
          </Suspense>
        </div>
      </div>
      <SnackbarListener />
    </>
  );
};

export default AppContainer;
