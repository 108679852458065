import MinitoolLayout from '../MinitoolLayout/Minitool';
import { ExtraPayout } from '@vestiaire/vc-backoffice-extra-payout';

const BulkImportDatafix = () => {
  return (
    <MinitoolLayout
      Component={ExtraPayout}
      translationKey="EXTRA_PAYOUT"
      containerStyle={{ overflow: 'auto', minHeight: '100vh' }}
    />
  );
};

export default BulkImportDatafix;
